import React from "react";
import { Grid } from "@mui/material";
import "../css/terms.css";
import logo from "../assets/images/logo.png";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

const Privacy = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });
  const location = useLocation();
  const { contact } = location.state || {}; // state içinden texts prop'unu al

  return (
    <div
      className="div1"
      style={{ color: "white", paddingTop: isMobile ? "70px" : "5px" }}
    >
      <center>
        <div class="mainLogoDivHeader">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              style={{
                width: isMobile ? "50%" : "10%",
                objectFit: "cover",
                marginTop: "10px",
              }}
            />
          </a>
        </div>
      </center>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={1} sm={1} md={1} />
        <Grid item xs={10} sm={10} md={10}>
          <br></br>
          <br></br>
          <center>
            <h1>Pati Game Studio</h1>
            <h1>StepMate!</h1>
            <br></br>
            <u>
              {" "}
              <strong>
                <p>PERSONAL DATA AND PRIVACY POLICY</p>
              </strong>
            </u>
            <br></br>
          </center>

          <p>
            Step Mate use and transfer to any other app of information received
            from Google APIs will adhere to
            <a href="https://developers.google.com/terms/api-services-user-data-policy">
              Google API Services User Data Policy
            </a>
            , including the Limited Use requirements.
          </p>
          <p>
            This Personal Data Protection and Privacy Policy (hereinafter
            referred to as the "Privacy Policy") is dated March 2023. Law No.
            6698 and Other Legislative Provisions. In case of changes, this
            issue will be notified separately.
          </p>
          <br></br>
          <ol style={{ color: "white" }}>
            <li>
              <strong>1. Purpose</strong>
            </li>
          </ol>
          <p>
            This policy explains the personal data collected by Pati Teknoloji
            Dış Ticaret A.Ş. through its mobile application named Step Mate,
            with whom this data is/can be shared, how it is/can be shared, how
            it is/can be used, your rights and methods of exercising your
            rights, and how you can choose whether or not to receive electronic
            commercial messages.
          </p>
          <br></br>
          <ol start="2" style={{ color: "white" }}>
            <li>
              <strong>
                {" "}
                2. Processing of Your Personal Data and Purpose and Legal Basis
                for Processing
              </strong>
            </li>
          </ol>
          <p>
            Your personal information (name, surname, ID number, e-mail address,
            home address, age and gender, education status, etc.) may be
            requested or already requested from you through the Step Mate
            application and website in order to provide better quality service
            to application users, within the limits set by the legislation and
            the obligations imposed by the legislation. Under the necessary
            security measures, such information will be used, processed and
            stored for the purposes specified herein based on your explicit
            consent.
          </p>
          <br></br>
          <ol start="3" style={{ color: "white" }}>
            <li>
              <strong>3. About Your Processed Personal Data</strong>
            </li>
          </ol>
          <p>
            If you use the Step Mate application, register and/or participate in
            events, we collect your information for the purposes specified
            herein, including but not limited to providing general and
            personalized campaigns, gifts, advantages, etc., solving problems
            you may experience due to application or other reasons. We process
            the information of our members, visitors, participants in the
            sections belonging to the name, surname and personal information
            filled in the relevant forms, location and IP address on the
            internet network, related fields, etc., your information, your
            preferences, both to conduct general statistical studies and to
            prepare general or special campaigns, advantages, gifts. In this
            context, your data (including your step information) is collected
            digitally, directly or indirectly, and stored as long as necessary
            within legal limits and under cyber security measures.
          </p>
          <p>
            Your personal information (name, surname, ID number, e-mail address,
            home address, age and gender, education status, etc.) may be
            requested or already requested from you through the Step Mate
            application and website in order to provide better quality service
            to application users, within the limits set by the legislation and
            the obligations imposed by the legislation. Under the necessary
            security measures, such information will be used, processed and
            stored for the purposes specified herein based on your explicit
            consent.
          </p>
          <br></br>
          <ol start="4" style={{ color: "white" }}>
            <li>
              <strong>4. Your Rights Regarding Personal Data</strong>
            </li>
          </ol>
          <p>
            You can contact us at {contact} to learn about the purposes of
            processing your personal data, the types of data being processed,
            whether your data is being transferred to third parties domestically
            or internationally, and to request correction, updating, or deletion
            of any incomplete or incorrect personal data. If you wish to
            permanently delete your account, you can do so from your profile
            (Open the StepMate application -{">"} Account -{">"} Profile -{">"}
            Edit Profile -{">"} Delete Account). Any unnecessary information
            associated with deleted accounts will be erased, destroyed, or
            anonymized within 30 days from the date of deletion, except for data
            that needs to be legally retained. Additionally, you may exercise
            your other rights under Article 11 of the Law on the Protection of
            Personal Data No. 6698.
          </p>
          <br></br>
          <ol start="5" style={{ color: "white" }}>
            <li>
              <strong>5. Transfer of Your Personal Data to 3rd Parties</strong>
            </li>
          </ol>
          <p>
            Your personal data may be shared with all kinds of service
            providers, suppliers, business partners of our Company and relevant
            institutions and third parties in case of legal requirements.&nbsp;
            These may also include those located abroad. The sharing here is
            limited in direct proportion to the purpose, it is aimed to increase
            the quality of the service provided and to ensure direct service
            fulfillment. In addition, your data that must be shared in
            accordance with legal regulations will be shared with the relevant
            institutions.
          </p>
          <br></br>
          <ol start="6" style={{ color: "white" }}>
            <li>
              <strong>6. Your Rights under Article 11 of Law No. 6698</strong>
            </li>
          </ol>
          <p>
            Pursuant to Article 11 of Law No. 6698; Everyone, by applying to the
            data controller;
            <ul>
              <li>a) To learn whether personal data is processed,</li>
              <li>
                b) To request information if personal data has been processed,
              </li>
              <li>
                c) To learn the purpose of processing personal data and whether
                it is used in accordance with its purpose,
              </li>
              <li>
                d) To know the third parties to whom personal data is
                transferred domestically or abroad,{" "}
              </li>
              <li>
                e) Requesting correction of personal data in case of incomplete
                or incorrect processing,
              </li>
              <li>
                f) Requesting the deletion or destruction of personal data
                within the framework of the conditions stipulated in Article 7,
              </li>
              <li>
                g) Requesting notification of the transactions made in
                accordance with subparagraphs (e) and (f) to third parties to
                whom personal data are transferred,{" "}
              </li>
              <li>
                h) Object to the occurrence of a result to the detriment of the
                person himself/herself by analyzing the processed data
                exclusively through automated systems,{" "}
              </li>
              <li>
                i) In case of damage due to unlawful processing of personal
                data, you have the right to demand the compensation of the
                damage".
              </li>
            </ul>
          </p>
          <p>
            In the event that the reasons requiring its processing disappear,
            your personal data will be deleted, destroyed or anonymized by the
            data controller ex officio or upon the request of the person
            concerned. You can send all your requests, questions and comments,
            if any, within this scope to {contact}
          </p>
          <p>
            However, we hereby inform you that Pati Teknoloji Dış Ticaret A.Ş.
            is not responsible for any damages arising directly or indirectly
            from the application and the services provided.
          </p>
          <br></br>
          <ol start="7" style={{ color: "white" }}>
            <li>
              <strong>7. Explicit Consent </strong>
            </li>
          </ol>

          <p>
            By accepting this Personal Data and Privacy Protection Policy; You
            will have given your explicit consent to be collected and stored for
            the purposes listed above, to be shared domestically and
            internationally, to improve and personalize the services offered, to
            be used for commercial purposes and to make notifications via text
            message, mail, voice message and other means and to be shared with
            third parties.
          </p>
          <br></br>
          <ol start="8" style={{ color: "white" }}>
            <li>
              <strong>8. Competent Court </strong>
            </li>
          </ol>
          <p>
            This Privacy Policy has been prepared in accordance with the
            provisions of Law No. 6698 and other legislation. In case of
            dispute, the laws of the Republic of Turkey shall apply. The
            Competent Court is Istanbul Central Courts and Execution Offices.
          </p>

          <br></br>
          <br></br>
          <br></br>
        </Grid>

        <Grid item xs={1} sm={1} md={1} />
      </Grid>
    </div>
  );
};

export default Privacy;
