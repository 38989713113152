import React, { useState } from "react";
import "../../css/navbar.css";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

import id from "../../assets/languages/web-id.json"; // Endonezyaca dil dosyası
import { useMediaQuery } from "react-responsive";

function Header(props) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  const handleLogoClick = () => {
    //console.log("Main logo clicked!");
    window.location.href = "/";
  };

  const handleGreenButtonClick = () => {
    //window.location.href = "http://stepmate.app/faq";
    if (props.texts == id) {
      navigate("/faq-ind");
    } else {
      navigate("/faq", { state: { contact: props.texts.contact } });
    }
  };

  const handleHowToButtonClick = () => {
    //window.location.href = "http://stepmate.app/faq";
    navigate("/faq-ind");
  };

  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");

  const scrollToGreenBgDiv = () => {
    const greenBgDiv = document.getElementById("greenBgDiv");
    if (greenBgDiv) {
      const { top, height } = greenBgDiv.getBoundingClientRect();
      const offset = window.pageYOffset;
      const targetPosition = top + offset - (window.innerHeight - height) / 2;
      if (active !== "nav__menu") {
        setActive("nav__menu");
      }

      // Icon Toggler
      if (icon !== "nav__toggler") {
        setIcon("nav__toggler");
      }

      window.scrollTo({ top: targetPosition, behavior: "smooth" });
    }
  };

  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };

  return (
    <nav className="nav">
      <a href="#" className="nav__brand">
        <div class="mainLogoDivHeader" onClick={handleLogoClick}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: "50%",
              objectFit: "cover",
              padding: isMobile ? "10px" : "",
            }}
          />
        </div>
      </a>

      <ul className={active}>
        <li style={{}}>
          <div
            class="headerWhiteDiv"
            onClick={handleGreenButtonClick}
            style={{
              cursor: "pointer",
              border: isMobile ? "1px solid" : "",
              borderRadius: isMobile ? "25px" : "",
            }}
          >
            <span
              style={{
                padding: isMobile ? "30px" : "",
                lineHeight: isMobile ? "40px" : "",
              }}
            >
              {props.texts.how_to_use}
            </span>
          </div>
        </li>

        <li style={{ width: "380px" }}>
          <div
            class="greenButtonDiv"
            style={{ width: "100%" }}
            onClick={scrollToGreenBgDiv}
          >
            {props.texts.download_app_today}
          </div>
        </li>
      </ul>
      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Header;
