// LanguageSelector.js
import { RemoveFromQueue } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { DropdownButton, Form, Dropdown } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const LanguageSelector = ({ languages, currentLanguage, onChangeLanguage }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });
  console.log("currentLang:", currentLanguage);

  const [selectedOption, setSelectedOption] = useState("Country");
  const [selectedFlag, setSelectedFlag] = useState();

  const handleSelect = (eventKey) => {
    const filteredLanguages = languages.filter(
      (lang) => lang.code === eventKey
    );
    const languageName = filteredLanguages.map((lang) => lang.name);
    const languageFlag = filteredLanguages.map((lang) => lang.flag);
    setSelectedFlag(languageFlag);
    setSelectedOption(languageName);
    onChangeLanguage(eventKey);
  };

  useEffect(() => {
    if (languages.length > 0) {
      setSelectedOption(languages[0].name);
      setSelectedFlag(languages[0].flag);
    }
  }, []);

  return (
    <>
      {/*
      <Form.Select
        value={currentLanguage}
        onChange={(e) => onChangeLanguage(e.target.value)}
        className="lang-selector"
      >
        {languages.map((lang) => (
          <option key={lang.code} value={lang.code}>
            <img src={lang.flag} alt="flag" />
            {lang.name}
          </option>
        ))}
      </Form.Select>
*/}

      <Dropdown onSelect={handleSelect} className="lang-selector">
        <Dropdown.Toggle id="dropdown-custom-components">
          <img
            src={selectedFlag}
            alt="Selected"
            style={{ width: "15%", marginRight: "5px" }}
          />
          {selectedOption}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {languages.map((lang) => (
            <Dropdown.Item eventKey={lang.code} style={{ padding: "5px" }}>
              <img
                src={lang.flag}
                style={{ width: "15%", marginRight: "5px" }}
                alt="flag"
              />
              {lang.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default LanguageSelector;
