import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/fonts/GothamBold.ttf";
import "./assets/fonts/GothamBook.ttf";
import "./assets/fonts/GothamBookItalic.ttf";
import "./assets/fonts/GothamLight.ttf";
import "./assets/fonts/GothamMedium.ttf";
import "./assets/fonts/GothamMediumItalic.ttf";
import "./assets/fonts/GothamBoldItalic.ttf";
import "./assets/fonts/GothamMedium_1.ttf";
// import "./assets/fonts/GothamXLightItalic.ttf"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
